import "./App.css";
import React, { Suspense, useState } from "react";
import DashboardLayoutBasic from "./components/Main";
import SignIn from "./components/sign-in/SignIn";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Worksapces from "./components/Worksapces/Worksapces";
import Reports from "./components/Reports/Reports";
import Calendar from "./components/Calendar/Calendar";
import Integrations from "./components/Integrations/Integrations";
import Support from "./components/Support/Support";

import Topics from "./components/Reports/Topics/Topics";
import Teach from "./components/Teach/Teach";

import "./scss/style.scss";
import { CSpinner } from "@coreui/react";
import Check from "./components/Check";
import ManageWorkspace from "./components/Worksapces/ManageWorkspace";
import Teams from "./components/Worksapces/Teams";
import People from "./components/Worksapces/People";
import TeamInfo from "./components/Worksapces/TeamInfo";
import ManageAccounts from "./components/Settings/ManageAccounts";
import GoogleCalendar from "./components/sign-in/GoogleCalendar";
import Feedback from "./components/Feedback/FeedbackForm";
import AcceptInvitation from "./components/Worksapces/AcceptInvitation";
import SlideDown from "./utilities/SlideNotification";
import OAuthCallback from "./components/sign-in/OauthCallback";

const Dashboard = React.lazy(
  () => import("./components/MainDashboard/Dashboard")
);

function App() {
  

  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          
          <Routes>
            <Route path="" element={<DashboardLayoutBasic />}>
              {/* <Route index element={<Dashboard />} /> */}
              <Route index element={<Navigate to="/organization" />} />

              <Route path="organization" element={<Worksapces />}>
                <Route path="" element={<ManageWorkspace />}>
                  <Route path="team/:w_id/:t_id" element={<TeamInfo />} />
                  <Route path="teams" element={<Teams />} />
                  <Route path="people" element={<People />} />
                </Route>
              </Route>

              <Route path="manage" element={<ManageAccounts />} />
              <Route path="reports" element={<Reports />} />
              <Route path="topics/:id" element={<Topics />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="integration" element={<Integrations />} />
              <Route path="support" element={<Support />} />
              <Route path="check" element={<Check />} />
              <Route path="team" element={<Teams />} />
              <Route path="google-calendar" element={<GoogleCalendar />} />
              <Route path="teach" element={<Teach />} />
            </Route>
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/accept-invite" element={<AcceptInvitation />} />
            <Route path="oauth/callback" element={<OAuthCallback/>}/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
