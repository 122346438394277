import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Popper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CreateTeam from "./CreateTeam";
import { team } from "./Request";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { teamDeletor } from "./Request";
import Consent from "../Utilities/Consent";
import DeleteAlert from "../Utilities/Alert";
import Message from "../Utilities/Message";
import { ToastContainer, toast } from "react-toastify";
const Teams = () => {
  const worksapceInfo = useSelector(
    (state) => state.workspace.workspaceDetails
  );
  const userInfo = useSelector((state) => state.userInfo.user);
  const workspaceId = worksapceInfo.length > 0 && worksapceInfo[0].id;
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    teamName: "",
    members: "",
    role: "",
    email: "",
    w_id: 0,
    t_id: 0,
    forward: false,
  });
  const [open, setOpen] = useState(false);
  const [openPoper, setOpenPopeer] = useState(false);
  const navigate = useNavigate();

  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const access_token = localStorage.getItem("access_token");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Responsive check

  const handlePoper = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopeer((previousOpen) => !previousOpen);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setOpenPopeer((previousOpen) => !previousOpen);
  };

  const canBeOpen = openPoper && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const fetchTeams = async () => {
    setLoading(true);
    await axios
      .get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (worksapceInfo.length > 0) {
      fetchTeams();
    }
  }, []);

  const handleCreateTeam = () => {
    if (worksapceInfo.length > 0) {
      setFormData({ ...formData, w_id: worksapceInfo[0].id });
      team({ team_name: formData.teamName }, workspaceId)
        .then((res) => {
          fetchTeams();
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleTeamDelete = (w_id, t_id) => {
    try {
      teamDeletor(w_id, t_id)
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            toast.success("Team Deleted");
            fetchTeams();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: "8px", md: "16px" }, // Padding adjusts for mobile and desktop
      }}
    >
      <ToastContainer />
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Consent />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add a New Team in your Organization"}
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ width: "100%", mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              Name your new Team
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              name="teamName"
              placeholder="Enter Team name"
              value={formData.teamName}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  teamName: e.target.value,
                  forward: !!e.target.value,
                })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateTeam}>Submit</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>
      {!loading && (
        <>
          {
            <Container
              fullWidth
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
                justifyContent: "space-between",
                marginBottom: "10px",
                alignItems: { xs: "flex-start", md: "center" }, // Aligns text and buttons properly
              }}
            >
              {worksapceInfo.length > 0 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: isMobile ? "18px" : "20px",
                      }}
                    >
                      Organization : {worksapceInfo[0].name}
                    </Typography>
                    {teams.length > 0 && (
                      <Typography
                        variant="body2"
                        sx={{ color: "#8000FF", margin: "3px" }}
                      >
                        {teams.length} Team in this Organization
                      </Typography>
                    )}
                  </Box>
                  {teams.length > 0 && (
                    <Button
                      onClick={() => setOpen(true)}
                      sx={{
                        marginTop: {
                          xs: "15px",
                          md: "-30px",
                          color: "#fff",
                          background:
                            "linear-gradient(90deg, #8000FF, #DE00FF)",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          borderRadius: "25px", // Makes it pill-shaped
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds a shadow
                          "&:hover": {
                            background:
                              "linear-gradient(90deg, #DE00FF, #8000FF)", // Reverse gradient on hover
                            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", // Enhanced hover effect
                          },
                          "&:disabled": {
                            background:
                              "linear-gradient(90deg, #8000FF, #DE00FF)", // Keeps the same gradient for disabled
                            opacity: 0.7,
                          },
                        },
                      }} // Add spacing for mobile
                    >
                      <AddIcon />
                      Add New Team
                    </Button>
                  )}
                </>
              )}
            </Container>
          }
          {teams.length > 0 ? (
            <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
              <Box
                sx={{
                  overflowX: "auto", // Adds horizontal scroll for small screens
                }}
              >
                <Table
                  sx={{
                    minWidth: 600,
                    fontSize: { xs: "10px", md: "14px" }, // Smaller font on mobile

                    "& th, & td": {
                      padding: { xs: "12px", md: "16px" }, // Adjust padding for mobile
                      wordWrap: "break-word", // Prevents long text overflow
                      maxWidth: "150px", // Limit cell width on mobile
                    },
                    // "& th": {
                    //   backgroundColor: "#f5f5f5", // Optional: Light background for headers
                    // },
                    // "& td": {
                    //   fontSize: { xs: "12px", md: "14px" },
                    //   padding: { xs: "2px", md: "16px" },
                    // },
                  }}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Team Name</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          display: { xs: "none", md: "table-cell" }, // Hide on mobile
                        }}
                      >
                        Members
                      </TableCell>
                      <TableCell align="left">manager</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          display: { xs: "none", md: "table-cell" }, // Hide on mobile
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.length > 0 &&
                      teams.map((team, index) => (
                        <TableRow
                          key={team.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          // onClick={() => navigate(`/organization/team/${workspaceId}/${team.id}`)}
                        >
                          <TableCell
                            align="left"
                            sx={{ ":hover": { cursor: "pointer" } }}
                            onClick={() =>
                              navigate(
                                `/organization/team/${workspaceId}/${team.id}`
                              )
                            }
                          >
                            {team.team_name}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              display: { xs: "none", md: "table-cell" }, // Hide on mobile
                            }}
                          >
                            {team.members_count}
                          </TableCell>
                          <TableCell align="left">
                            {team.workspace_created_by}
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{
                                display: "flex",
                                gap: isMobile ? "2px" : "10px",
                                flexWrap: { xs: "wrap", md: "nowrap" }, // Adjust for smaller screens
                              }}
                            >
                              <VisibilityIcon
                                onClick={() =>
                                  navigate(
                                    `/organization/team/${team.workspace_id}/${team.id}`
                                  )
                                }
                                sx={{ ":hover": { cursor: "pointer" } }}
                              />
                              <DeleteAlert
                                deleting={`Team`}
                                name={team.team_name}
                                controlDelete={() =>
                                  handleTeamDelete(team.workspace_id, team.id)
                                }
                              >
                                {" "}
                                <DeleteIcon
                                  sx={{
                                    ":hover": { cursor: "pointer" },
                                    color: theme.palette.text.primary,
                                  }}
                                />
                              </DeleteAlert>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "70%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CreateTeam formData={formData} setFormData={setFormData} />
              <Button type="submit" onClick={handleCreateTeam}>
                Add
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Teams;
