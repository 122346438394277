import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import Diversity3Icon from "@mui/icons-material/Diversity3";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./reports.css";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { AppProvider } from "@toolpad/core/AppProvider";
import MyReport from "./MyReport";
import ReportTable from "./ReportTable";
import { useSelector } from "react-redux";
import {
  setWorkspace,
  setWorkspaceInfo,
} from "../../Store/Slices/WorkspaceSlice";


const Reports = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(""); // For dropdown value
  const [moms, setMoms] = useState([]);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const access_token = localStorage.getItem("access_token");
  

  const workspaceData = JSON.parse(sessionStorage.getItem("organization_id"));


  const handleTab = (event, newValue) => {
    setValue(newValue);
  };
  const workspaceId =
    workspaceData && workspaceData.length > 0 && workspaceData[0].id;
  const fetchTeams = async () => {
    // setLoading(true);
    await axios
      .get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.data);
          // console.log(`getting data from Teams ${res}`);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };
  useEffect(() => {
    if (workspaceData && workspaceData.length > 0) {
      fetchTeams();
    }
  }, []);
  const filterMoms = moms.filter((mom) => {
    if(selectedTeam === ""){
      return mom
    }
    else if(mom.team_name === selectedTeam){
      return mom
    }
  });
  const handleChange = (event) => {
    console.log(event.target.value);
    
    setSelectedTeam(event.target.value);
    
    
  };


  useEffect(()=>{
    
  },[])

  // MOM
  const fetchMoms = async () => {
    await axios.get(`${dashboard_backend}/moms/mom-list/`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setMoms(res.data);
        
        
        //   setLoading(false);
        
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  useEffect(()=>{
   fetchMoms();
  },[])
  
  const pageUpdate = ()=>{
  fetchMoms();
  }

  return (
    <Container maxWidth="lg" sx={{ padding: isMobile ? 1 : 2 }}>
      <Container
        sx={{
          height: "60px",
          width: "100%",
          display: "flex",
          borderBottom: "1px solid #e0e0e0",
          borderColor: theme.palette.divider,
          flexDirection: isMobile ? "column" : "row",
          padding: isMobile ? 1 : 2,
        }}
      >
        <Box
          className="reports"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {workspaceData && workspaceData.length > 0 && (
            <SvgIcon>
              <path
                d="M7.5 11H4.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 11.76 3 12.04 3 12.6V21m13.5-10h2.9c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C21 11.76 21 12.04 21 12.6V21m-4.5 0V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C14.98 3 14.42 3 13.3 3h-2.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C7.5 4.52 7.5 5.08 7.5 6.2V21M22 21H2m9-14h2m-2 4h2m-2 4h2"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </SvgIcon>
          )}
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontSize: isMobile ? "14px" : "16px",
              marginLeft: "10px",

              //
              color: theme.palette.text.primary,
            }}
          >
            {workspaceData && workspaceData.length > 0
              ? workspaceData[0].name
              : "Reports"}
          </Typography>
          {/* <Typography variant="body2">
            {workspaceData.length > 0 ? workspaceData[0].name : "Reports"}
          </Typography> */}

          <FormControl
            sx={{
              m: 2,
              minWidth: 200,
              height: "40px",
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
              padding: "4px",
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",

              //
              marginTop: isMobile ? 2 : 0,
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.divider,
            }}
            variant="standard"
          >
            <SvgIcon>
              <path
                d="M16 7c0-.93 0-1.395-.102-1.776a3 3 0 0 0-2.121-2.122C13.395 3 12.93 3 12 3c-.93 0-1.395 0-1.777.102a3 3 0 0 0-2.12 2.122C8 5.605 8 6.07 8 7M5.2 21h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 19.48 22 18.92 22 17.8v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 7 19.92 7 18.8 7H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 8.52 2 9.08 2 10.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 21 4.08 21 5.2 21Z"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </SvgIcon>

            <Select
              //   value={10}
              // onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "100%",
                // color: "#333", // Matching text color
                fontSize: "15px", // Consistent text size
                fontWeight: 400, // Same weight as other text
                fontStyle: "normal", // Prevent italic or slanted text
                color: theme.palette.text.primary, // Text color remains dynamic
                backgroundColor: theme.palette.background.paper, // Fix background for light & dark mode
                "& .MuiSelect-select": {
                  padding: "8px 10px", // Consistent padding
                },
                "&:focus": {
                  backgroundColor: theme.palette.background.paper, // Prevent black background on focus
                },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.divider, // Match borders with theme
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
              labelId="team-dropdown-label"
              value={selectedTeam}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em style={{ fontStyle: "normal", paddingLeft: "10px" }}>
                  {" "}
                  All Teams
                </em>{" "}
                {/* Changed default option */}
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.team_name}>
                  {team.team_name}
                </MenuItem>
              ))}

              {/* <MenuItem value ={}></MenuItem> */}
              {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
      </Container>
      <AppProvider>
        <Divider />
        <Box sx={{ width: "100%", p: "10px 20px", mt: 2 }}>
          <Tabs
            onChange={handleTab}
            value={value}
            aria-label="Tabs where selection follows focus"
            selectionFollowsFocus
            //
            sx={{
              ".MuiTab-root": { fontSize: isMobile ? "10px" : "14px" },
            }}
          >
            <Tab label="My Report" />
            <Tab label="Shared With Me" />
            <Tab label="Incomplete" />
          </Tabs>
        </Box>
        <MyReport />
        <ReportTable moms={filterMoms} pageUpdate = {pageUpdate} />
      </AppProvider>
    </Container>
  );
};

export default Reports;
