import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
    const navigate = useNavigate();
    const auth_host = process.env.REACT_APP_AUTH_HOST
    const authorizeCode = async (authCode) => {
        try {
            // Send the authorization code to the backend for processing
            const response = await fetch(`${auth_host}/google-callback/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: authCode }),
            });

            if (response.status === 200) {
                // Parse the response data
                const data = await response.json();
                

                // Store tokens in localStorage
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);

                // Navigate to the homepage or another URI
                navigate('/');
            } else {
                console.error(`Error: Received status code ${response.status}`);
                navigate('/error'); // Navigate to an error page if needed
            }
        } catch (err) {
            console.error('Error:', err);
            navigate('/error'); // Navigate to an error page if needed
        }
    };


    useEffect(() => {
        // Extract the authorization code from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        console.log(authCode)
        if (authCode) {
            authorizeCode(authCode)
        } else {
            // No code present in the URL (redirect to login)
            navigate('/login?error=no_code');
        }
    }, [navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Processing your login...</h1>
            <p>Please wait while we complete your login.</p>
        </div>
    );
};

export default OAuthCallback;
