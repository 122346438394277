import * as React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Teams from "./Teams";
import People from "./People";
import {
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import {
  setWorkspace,
  setWorkspaceInfo,
} from "../../Store/Slices/WorkspaceSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

export default function ManageWorkspace() {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is small (mobile view)

  // This code remains here till the dashboard is not ready
  const [isWorkSpace, setIsWorkSpace] = React.useState(false);
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const access_token = localStorage.getItem("access_token");
  const fetchWorkspaceDetails = async () => {
    try {
      const res = await axios.get(`${dashboard_backend}/team/workspaces/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (res.data.length > 0) {
        sessionStorage.setItem("organization_id", JSON.stringify(res.data));
        localStorage.setItem("organization", true);
        setIsWorkSpace(true);
        dispatch(setWorkspace(true));
        dispatch(setWorkspaceInfo(res.data));
      } else {
        localStorage.setItem("organization", false);
        dispatch(setWorkspace(false));
        dispatch(setWorkspaceInfo({}));
      }
    } catch (err) {
      console.error("Error fetching workspace details:", err);
    }
  };

  React.useEffect(() => {
    fetchWorkspaceDetails();
  }, []);
  // End of the code remains here till the dashboard is not ready

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      navigate("teams");
    }
    if (newValue === "2") {
      navigate("people");
    }
  };
  React.useEffect(() => {
    if (window.location.pathname === "/organization") {
      navigate("teams");
    } else if (window.location.pathname === "/organization/teams") {
      setValue("1");
    } else if (window.location.pathname === "/organization/people") {
      setValue("2");
    }
  }, [window.location.pathname]);

  

  return (
    <Box
      sx={{
        width: isMobile ? "60%" : "100%",
        typography: "body1",
        padding: isMobile ? "0px" : "10px",
        margin: isMobile ? "3px" : "20px",
      }}
    >
      <Typography
        variant="h6"
        component="h4"
        gutterBottom
        sx={{
          fontSize: isMobile ? "16px" : "20px", // Adjust font size for mobile view
        }}
      >
        Manage Organization
      </Typography>
      <Divider />
      <TabContext value={value} sx={{ fontSize: isMobile ? "8px" : "14px" }}>
        <Box
          sx={{
            fontSize: isMobile ? "6px" : "14px", // Adjust tab font size for mobile view
            borderBottom: 1,
            borderColor: "divider",

            marginBottom: isMobile ? "6px" : "16px", // Reduce spacing for mobile view
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTab-root": {
                fontSize: isMobile ? "10px" : "14px", // Adjust tab font size for mobile view
                minWidth: isMobile ? "40px" : "100px", // Adjust tab width for mobile view

                gap: isMobile ? 0 : 8,
              },

              gap: isMobile ? 0 : 8,
            }}
          >
            <Tab
              label="Teams"
              value="1"
              sx={{
                fontSize: isMobile ? "2px" : "14px",
                gap: isMobile ? 0 : 8,
                font: "bold",
              }}
            />
            <Tab
              label="People"
              value="2"
              sx={{
                fontSize: isMobile ? "2px" : "14px",
                gap: isMobile ? 0 : 8,
              }}
            />
            {/* <Tab label="Item Three" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{
            fontSize: isMobile ? "4px" : "14px", // Adjust tab font size for mobile view
            padding: isMobile ? "0px 0" : "16px 0", // Adjust TabPanel padding for mobile view
            gap: isMobile ? 0 : 8,
          }}
        ></TabPanel>
        <TabPanel
          value="2"
          sx={{
            fontSize: isMobile ? "4px" : "14px", // Adjust tab font size for mobile view

            padding: isMobile ? "0px 0" : "16px 0", // Adjust TabPanel padding for mobile view

            gap: isMobile ? 0 : 8,
          }}
        ></TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
      <Outlet />
    </Box>
  );
}
