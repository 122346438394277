import React, { useState, useEffect } from "react";
import "./SlideDown.css"; // Add custom styles here

const SlideDown = ({ message, link, visible, onClose,onInstall }) => {
  return (
    <div className={`slide-down ${visible ? "show" : ""}`}>
      <p>
        {message}{" "}
        <a href={link} target="_blank" rel="noopener noreferrer" onClick={onInstall} >
          Click here
        </a>
      <button onClick={onClose}>✕</button>
      </p>
    </div>
  );
};

export default SlideDown;
