import {
  Box,
  Checkbox,
  Container,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import IOSSwitch from "../Utilities/Switch";

const MyReport = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Breakpoint for mobile screens

  return (
    <Container
      sx={{
        width: "100%",
        padding: isMobile ? "10px" : "20px",
        bgcolor: theme.palette.background.default,
        color: theme.palette.text.primary, // Ensure text is visible
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          // flexWrap: "wrap", // Allow items to wrap in mobile view
          // justifyContent: isMobile ? "center" : "start",
          flexDirection: "column", // Stack vertically in mobile
        }}
      >
        {/* Checkbox Group */}
        <Box
          sx={{
            // flex: isMobile ? "1 1 100%" : "1 1 auto", // Take full width in mobile
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: "8px",
            display: "flex",

            flexDirection: isMobile ? "column" : "row", // Stack items vertically on mobile
            alignItems: "center",
            justifyContent: isMobile ? "space-between" : "flex-start",
            padding: isMobile ? "8px" : "15px",
            gap: "10px",
            width: "100%", // Ensure full width
            boxSizing: "border-box",
            bgcolor: theme.palette.background.paper,
          }}
        >
          {/* <Checkbox {...label} defaultChecked />
          <span>Meetings</span>
          <Checkbox {...label} />
          <span>Topics</span> */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <Checkbox {...label} defaultChecked />
            <Typography
              variant="body2"
              sx={{
                fontSize: isMobile ? "12px" : "14px",
                color: theme.palette.text.primary,
              }}
            >
              Meetings
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <Checkbox {...label} />
            <Typography
              variant="body2"
              sx={{
                fontSize: isMobile ? "12px" : "14px",
                color: theme.palette.text.primary,
              }}
            >
              Topics
            </Typography>
          </Box>
        </Box>

        {/* Group Toggle */}
        <Box
          sx={{
            // width: "150px",
            // border: "1px solid grey",
            // flex: isMobile ? "1 1 100%" : "0 0 150px", // Take full width in mobile
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: "10px",
            display: "flex",
            // flexDirection: isMobile ? "row" : "row", // Keep toggle inline
            alignItems: "center",
            justifyContent: "space-between",
            padding: isMobile ? "8px" : "15px",
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            gap: "10px",
            boxSizing: "border-box",
            width: "100%", // Ensure full width
          }}
        >
          {/* <span>Group</span> */}
          <Typography
            variant="body2"
            sx={{
              fontSize: isMobile ? "14px" : "16px",
              color: theme.palette.text.primary,
            }}
          >
            Group
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: isMobile ? "auto" : "auto", // Ensure it adapts in mobile
            }}
          >
            <IOSSwitch />
          </Box>
        </Box>
        {/* <Box
          sx={{
            width: "100px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            id="standard-select-currency"
            variant="standard"
            select
            // label="Select"
            defaultValue="EUR"
            // helperText="Please select your currency"
            size="medium"
            sx={{
              padding: "2px",
              // '& .MuiInputBase-input': {
              //     fontSize: '12px', // Change the font size here
              // },
            }}
          >
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="USD" sx={{ fontSize: "12px" }}>
              USD
            </MenuItem>
            <MenuItem value="BTC" sx={{ fontSize: "12px" }}>
              BTC
            </MenuItem>
            <MenuItem value="ETH" sx={{ fontSize: "12px" }}>
              ETH
            </MenuItem>
          </TextField>
        </Box>
        <Box
          sx={{
            width: "80px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Select
            value={10}
            // onChange={handleChange}
            variant="standard"
            size="small"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              "& .MuiInputBase-input": {
                borderBottom: "none",
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </Box> */}
      </Box>
    </Container>
  );
};

export default MyReport;
