import { React, useRef, useState, useMemo, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AppProvider } from "@toolpad/core/AppProvider";
import {
  PageContainer,
  PageContainerToolbar,
} from "@toolpad/core/PageContainer";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// API
import apiClient from "../AxiosInterceptors/Interceptor";
import SyncIcon from '@mui/icons-material/Sync';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  LinearProgress,
  Box,
  Tabs,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setWorkspace,
  setWorkspaceInfo,
} from "../../Store/Slices/WorkspaceSlice";
import { toast, ToastContainer } from "react-toastify";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import './Teach.css'

const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
const backend = process.env.REACT_APP_BACKEND_HOST;
const NAVIGATION = [
  {
    segment: "",
    title: "Teach Liznr",
    icon: <DashboardIcon />,
  },
];

function useDemoRouter(initialPath) {
  const [pathname, setPathname] = useState(initialPath);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  return router;
}

function PageToolbar({ onUploadSuccess }) {
  const [open, setOpen] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const worksapceInfo = useSelector(
    (state) => state.workspace.workspaceDetails
  );
  const workspaceIds = worksapceInfo.length > 0 ? worksapceInfo[0].id : null;
  const [workspaceId, setWorkspaceId] = useState(workspaceIds);
  const access_token = localStorage.getItem("access_token");
  const [teams, setTeams] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Responsive check

  const [formData, setFormData] = useState({
    title: "",
    domain: "",
    team: "",
  });

  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const [upload, setUpload] = useState(false);
  const dispatch = useDispatch();

  const fetchTeams = async () => {
    // setLoading(true)
    await axios
      .get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.data);
          // setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  };

  const workspace = async () => {
    try {
      await axios
        .get(
          `${dashboard_backend}/team/workspaces/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 200) {
            // return res.data
            setWorkspaceId(res.data[0].id);
            dispatch(setWorkspace(true));
            dispatch(setWorkspaceInfo(res.data));
            if (workspaceId !== null) {
              fetchTeams();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (worksapceInfo.length > 0) {
      fetchTeams();
    } else {
      workspace();
    }
  }, [workspaceId]);

  // Function to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setUploadProgress(0); // Reset progress on close
  };

  // Handle input change for the title
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      alert("Please select a file");
      return;
    }

    // Create FormData and append the file and title
    const data = new FormData();
    data.append("title", formData.title); // Add the title to the FormData
    data.append("file", file); // Add the file to the FormData
    // data.append('user', 1); // Add the user field if needed
    data.append("team_name", formData.team);

    const access_token = localStorage.getItem("access_token");
    setUpload(false);
    await axios
      .post(`${dashboard_backend}/documents/`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percent = Math.round((current / total) * 100);
          setUploadProgress(percent); // Update progress
        },
      })
      .then((response) => {
        handleClose(); // Close the dialog after successful upload
        // window.location.reload()
        onUploadSuccess();

        // navigate('/teach')
      })
      .catch((error) => {
        handleClose(); // Close the dialog on error
        console.error("Error uploading file and details:", error);
        toast.error("There is an issue with the file extension please try .pdf, .txt, .docx, .doc");
        setUpload(false);
      });
  };

  const options = [
    { label: "Technology", value: "tech" },
    { label: "Hospital", value: "hospital" },
    { label: "Auto Mobiles", value: "automobile" },
    { label: "Telecom", value: "telecom" },
  ];

  return (
    <PageContainerToolbar
      sx={{
        display: "flex",
        justifyContent: "flex-start", // Align to the start
        alignItems: "center", // Align items vertically
        padding: "0 16px", // Add some padding

        marginBottom: "60px",
        marginTop: "40px",
      }}
    >
      <Stack
        spacing={1}
        alignItems="center"
        direction={isMobile ? "column" : "row"}
        sx={{
          width: "100%",
          justifyContent: "flex-start", // Align children to the start
          flexWrap: "wrap", // Allow wrapping on smaller screens
          gap: 5,
        }}
      >
        <h2
          sx={{
            marginRight: "50px",
            color: theme.palette.text.primary,
            fontSize: "1.5rem",
            gap: 4,
          }}
        >
          Train Liznr
        </h2>
        <Button
          variant="outlined"
          size="small"
          color="neutral"
          startIcon={<UploadIcon fontSize="inherit" />}
          onClick={handleClickOpen}
          sx={{
            // display: "block", // Centering the button
            margin: "20px 60px", // Adds vertical spacing and centers horizontally
            width: "40%", // Increases the button's width
            maxWidth: "150px", // Ensures it doesn't become too wide
            padding: "10px", // Makes the button larger
            background: "linear-gradient(90deg, #8000FF, #DE00FF)", // Gradient color
            color: "#fff",
            fontWeight: "bold",
            textTransform: "uppercase",
            borderRadius: "25px", // Makes it pill-shaped
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds a shadow
            "&:hover": {
              background: "linear-gradient(90deg, #DE00FF, #8000FF)", // Reverse gradient on hover
              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", // Enhanced hover effect
            },
            "&:disabled": {
              background: "linear-gradient(90deg, #8000FF, #DE00FF)", // Keeps the same gradient for disabled
              opacity: 0.7,
            },
          }}
        >
          Upload
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Upload File</DialogTitle>
          <DialogContent>
            {/* <TextField
                            autoFocus
                            margin="dense"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            value={formData.title}
                            onChange={handleInputChange}
                        /> */}
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  backgroundColor: "white", // Adds background to prevent overlap
                  px: 0.5, // Padding around label text
                  transform: "translate(14px, 14px) scale(1)", // Default position
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -4px) scale(0.85)", // Adjust position when focused
                    backgroundColor: "white", // Keep the background when it shrinks
                    padding: "0 4px", // Add spacing around the text
                  },
                }}
              >
                Select Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={formData.team}
                label="Domain"
                onChange={handleInputChange}
                sx={{
                  color: "black",
                  "& .MuiSelect-select": {
                    padding: "16.5px 14px", // Ensures proper alignment of the text
                  },
                  "& fieldset": {
                    borderColor: "#ccc", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#888", // Hover state border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1976d2", // Focused border color (default MUI primary color)
                  },
                }}
                name="team"
              >
                {teams &&
                  teams.map((data, i) => (
                    <MenuItem key={i} value={data.team_name}>
                      {data.team_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <input
              type="file"
              ref={fileInputRef}
              style={{ marginTop: "16px" }}
            />
            {uploadProgress > 0 && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ marginTop: "16px" }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={uploadProgress > 0 && uploadProgress < 100} // Disable if upload is ongoing
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </PageContainerToolbar>
  );
}

export default function Teach(props) {
  const { window } = props;
  const router = useDemoRouter("/orders");
  const theme = useTheme();
  const [documents, setDocuments] = useState([]);
  const demoWindow = window ? window() : undefined;
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [correctionData, setCorrectionData] = useState(null);
  const [value, setValue] = useState('1');
  const [isAdmin, setIsAdmin] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [notification, setNotification] = useState("")
  const [selectedRows, setSelectedRows] = useState([]);

  // Dashboar Backend endpoint
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (notification) {
      toast.success(notification)
    }
  }, [notification])

  // Fetching the word and correction data from the backend
  const getCorrectionData = async () => {
    const access_token = localStorage.getItem("access_token");
    await axios
      .get(`${dashboard_backend}/documents/editMeaning/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        setCorrectionData(response.data.data);
        setIsAdmin(response.data.admin);


        // const data = response.data;
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  }
  useEffect(() => {
    getCorrectionData();
  }, []);

  const getDocuments = async () => {
    const access_token = localStorage.getItem("access_token");
    await axios
      .get(`${dashboard_backend}/documents/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        setDocuments(response.data);
        // const data = response.data;
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });

    // const response = await apiClient.get('/documents/')
    // console.log(response);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      getDocuments();
      setShouldRefresh(false); // Reset after refresh
    }
  }, [shouldRefresh]);

  const controlDelete = async (document_id) => {
    try {
      const response = await axios.delete(
        `${dashboard_backend}/documents/${document_id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response.status === 204) {
        getDocuments();
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return new Intl.DateTimeFormat("en-US", {
      dateStyle: "medium", // "full", "long", "medium", "short"
      timeStyle: "short", // Optional: Add if you want to include time
    }).format(date);
  };

  const handleAccept = async (id) => {
    try {
      await axios.put(`${dashboard_backend}/documents/updateMeaning/${id}/`, { "edited": true }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      }).then((response) => {
        
        getCorrectionData();
        toast.success("Accepted");
        
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }
  }
  const acceptSelectd = async () => {
    try {
      console.log("IDs to update:", selectedRows); // Debug: Log selected rows
  
      // Send requests concurrently and wait for all of them to complete
      const results = await Promise.all(
        selectedRows.map(async (id) => {
          console.log(`Sending request for ID: ${id}`); // Debug: Log each request
          return axios.put(
            `${dashboard_backend}/documents/updateMeaning/${id}/`,
            { edited: true },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
        })
      );
  
      console.log("All requests completed successfully:", results); // Debug: Log responses
      toast.success("Accepted");
  
      // Refresh data and clear selection
      getCorrectionData();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error in updating rows:", error); // Debug: Log errors
      toast.error("An error occurred while updating the rows.");
    }
  };
  
  
  const handleReject = async (id) => {
    try {
      await axios.delete(`${dashboard_backend}/documents/deleteMeaning/${id}/`, {
        headers: {
          
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      }).then((response) => {

        getCorrectionData();
        toast.success("Rejected");
        console.log(response);
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }
  }
  
  const rejectSelectd = async () => {
    try {
      // Wait for all the PUT requests to complete
      await Promise.all(
        selectedRows.map((id) =>
          axios.delete(
            `${dashboard_backend}/documents/deleteMeaning/${id}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
      ));
  
      // Display success toast and fetch updated data
      getCorrectionData();
      toast.success("Rejected");
      setSelectedRows([]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleRefresh = async () => {
    setRefreshing(true)
    await getCorrectionData();
    setRefreshing(false)
  }

  // Handle individual row selection
  const handleRowSelect = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  // Handle "Select All" functionality
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      
      const allIds = correctionData.map((doc) => doc.id);
      console.log(allIds);
      
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <ToastContainer />
      {!isAdmin &&
        <Paper
          sx={{
            p: 2,
            width: "100%",
          }}
        >
          <PageToolbar onUploadSuccess={() => setShouldRefresh(true)} />
          {/* <PageContainer
      slots={{
        toolbar: () => (

        ),
      }}
      breadcrumbs={[{ label: "" }]}
      > */}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Team</TableCell>
                  {/* <TableCell>Domain</TableCell> */}
                  <TableCell align="left">Date</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{data.team_name}</TableCell>
                    {/* <TableCell>{'-'}</TableCell> */}
                    <TableCell>
                      {data.uploaded_at && formatDate(data.uploaded_at)}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        const parts = data.file.split("/");
                        return parts[parts.length - 1];
                      })()}
                    </TableCell>
                    <TableCell
                      sx={{ cursor: "pointer" }}
                      onClick={() => controlDelete(data.id)}
                    >
                      {<DeleteOutlineIcon />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </PageContainer> */}


        </Paper>}


      {isAdmin && <Box sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Training Docs" value="1" />
              <Tab label="Meaning Correction" value="2" />

            </TabList>
          </Box>
          <TabPanel value="1">
            <Paper
              sx={{
                p: 2,
                width: "100%",
              }}
            >
              <ToastContainer />
              <PageToolbar onUploadSuccess={() => setShouldRefresh(true)} />
              {/* <PageContainer
      slots={{
        toolbar: () => (

        ),
      }}
      breadcrumbs={[{ label: "" }]}
      > */}

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Team</TableCell>
                      {/* <TableCell>Domain</TableCell> */}
                      <TableCell align="left">Date</TableCell>
                      <TableCell>File</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{data.team_name}</TableCell>
                        {/* <TableCell>{'-'}</TableCell> */}
                        <TableCell>
                          {data.uploaded_at && formatDate(data.uploaded_at)}
                        </TableCell>
                        <TableCell>
                          {(() => {
                            const parts = data.file.split("/");
                            return parts[parts.length - 1];
                          })()}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          onClick={() => controlDelete(data.id)}
                        >
                          {<DeleteOutlineIcon />}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </TabPanel>

          <TabPanel value="2">
            <Paper
              sx={{
                p: 2,
                width: "100%",
              }}
            >
              <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                <Table>
                  <TableHead sx={{ position: "relative" }}>
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        Meaning Correction

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={
                            selectedRows.length > 0 &&
                            selectedRows.length === correctionData.length
                          }
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < correctionData.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>S.No</TableCell>
                      <TableCell>Word</TableCell>
                      {/* <TableCell>Domain</TableCell> */}
                      <TableCell align="left">Meaning</TableCell>
                      <TableCell>Requested By</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                    <SyncIcon className={refreshing && "refresh"} onClick={handleRefresh} sx={{ cursor: "pointer", position: "absolute", right: "20px", top: "20px" }} />
                  </TableHead>
                  <TableBody>
                    {correctionData && correctionData.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(data.id)}
                            onChange={() => handleRowSelect(data.id)}
                          />
                        </TableCell>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{data.word}</TableCell>
                        {/* <TableCell>{'-'}</TableCell> */}
                        <TableCell>{data.meaning}</TableCell>
                        <TableCell>{data.edited_by_first_name}</TableCell>
                        <TableCell
                        // sx={{ cursor: "pointer" }}
                        // onClick={() => controlDelete(data.id)}
                        align="center"
                        sx={{ display: "flex", gap: "10px" }}
                        >
                            <Button sx={{cursor: "pointer" }} variant="contained" color="success" titleAccess="Approve" onClick={() => handleAccept(data.id)}>Accept</Button>
                            <Button sx={{ cursor: "pointer" }} variant="contained" color="error" titleAccess="Reject" onClick={() => handleReject(data.id)}>Reject</Button>
                          {/* <div style={{ display: "flex", gap: "10px" }}>
                          </div> */}
                        </TableCell>
                      </TableRow>
                    ))}
                    {correctionData.length===0 && 
                    <TableRow>
                      <TableCell colSpan={6} rowSpan={3} align="center"> No meaning correction request found</TableCell>
                    </TableRow>
                    }
                  </TableBody>
                 {selectedRows.length > 0 && correctionData && correctionData.length>0 && <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Button variant="contained" onClick={acceptSelectd} color="success" sx={{mr:"10px",fontSize:"12px"}} >Accept Selected</Button>
                        <Button variant="contained" onClick={rejectSelectd} color="error" sx={{fontSize:"12px"}}>Reject Selected</Button>
                      </TableCell>
                    </TableRow>}
                </Table>
              </TableContainer>
            </Paper>
          </TabPanel>

        </TabContext>
      </Box>}
    </>
  );
}
