
/*global chrome*/
import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import getSignInTheme from "./theme/getSignInTheme";

import { Link as Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import { useMsal } from "@azure/msal-react";

import LiznrLight from "../../Assets/LiznrLight.png";
import LiznrFav from "../../Assets/LiznrExtenIco.png";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  // margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.02) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.03) 0px 15px 35px -5px",
  backgroundColor: "#ffffff", // Default to white
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.2) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.04) 0px 15px 35px -5px",
    backgroundColor: "#ffffff", // Force white background in dark mode
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: 20,
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  }),
}));

export default function SignIn() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignInTheme = createTheme(getSignInTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [tokenClient, setTokenClient] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);

  const navigate = useNavigate();
  const backend = process.env.REACT_APP_BACKEND_HOST;
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // Privacy policy and terms and condition
  const privacy_policy = process.env.REACT_APP_PRIVACY_POLICY;
  const terms_and_conditions = process.env.REACT_APP_TERMS_OF_SERVICE;
  const auth_host = process.env.REACT_APP_AUTH_HOST


  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async () => {
    setLoading(true); // Show loader
    try {
      const response = await axios.get(`${auth_host}/google-login/`);
      
      if (response.data.auth_url) {
        window.location.href = response.data.auth_url;
        console.log(response.data);
        
      } else {
        console.error('Google Auth URL not returned by the API');
      }
    } catch (error) {
      console.error('Error fetching Google login URL:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  };



  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  // // This is for google login
  React.useEffect(() => {
    const initializeGoogleClient = () => {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: googleClientId, // Replace with your client ID
        
        // scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        prompt:'consent',
        access_type: 'offline',
        callback: handleAuthResponse,
      });
      setTokenClient(client);
    };

    if (window.google && window.google.accounts && window.google.accounts.oauth2) {
      initializeGoogleClient();
    }
  }, []);

  const handleAuthResponse = async (response) => {
    if (response.error) {
      console.error('Authentication failed:', response.error);
      return;
    }
    setIsSignedIn(true);
    console.log('Access token:', response);

    // Fetch user profile information
    try {
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      });
      const userInfoData = await userInfoResponse.json();
      setUserInfo(userInfoData); // Set user info in state
      console.log('User info:', userInfoData);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  // const handleGoogleLogin = () => {
  //   if (tokenClient) {
  //     tokenClient.requestAccessToken();
  //     console.log("Token ",tokenClient);

  //   } else {
  //     console.error('Token client not initialized');
  //   }
  // };


  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const response = await axios.post(`${backend}/login/`, {
        username: data.get("email"),
        password: data.get("password"),
      });
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      console.log(response);

      navigate("/");
    } catch (error) {
      console.log("error while login", error);
      navigate("/sign-in");
    }
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  async function sendLoggedInMessageToExtension(access, refresh) {
    try {
      const response = await chrome.runtime.sendMessage(
        "jboofaeiajhcbcgfpklmoooedclfggim",
        { type: "LOGIN", access_token: access, refresh_token: refresh }
      );
      console.log("Response from extension:", response);
    } catch (error) {
      console.error("Error sending message to extension:", error);
    }
  }

  async function checkLoginStatusFromExtension() {
    const extensionId = "jboofaeiajhcbcgfpklmoooedclfggim"; // Replace with your extension's ID

    try {
      const response = await chrome.runtime.sendMessage(extensionId, {
        type: "CHECK_LOGIN",
      });

      if (response && response.status === "success") {
        console.log("User is logged in on the extension");
      } else {
        console.log("User is not logged in on the extension");
      }
    } catch (error) {
      console.error("Failed to check login status from extension:", error);
    }
  }

  const handleSuccess = async (data) => {
    
    // console.log(data);
    
    try {
      if (data) {
        localStorage.setItem("picture", data.picture);
      }
      await axios
        .post(`${backend}/auth/google/`, {
          email: data.email,
          name: data.name,
          display_name : data.name
        })
        .then((res) => {
          const { access, refresh } = res.data;

          // Store tokens in localStorage or sessionStorage
          localStorage.setItem("access_token", access);
          localStorage.setItem("refresh_token", refresh);
          
          // This is important
          // handleGoogleLogin()

          // notifyExtensionLogin(access);
          sendLoggedInMessageToExtension(access, refresh);

          navigate("/");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const { instance } = useMsal();
  // Microsoft
  const handleLogin = async () => {
    await instance
      .loginPopup(["openid", "profile", "User.Read"])
      .then((response) => {
        // console.log(response.account);
        handleSuccess({
          email: response.account.username,
          name: response.account.name,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <ThemeProvider theme={showCustomTheme ? SignInTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card
          variant="outlined"
          style={{ padding: "5px", textAlign: "center" }}
        >
          <div style={{ marginTop: "5px auto" }}>
            <img
              src={LiznrLight}
              alt="logo"
              style={{ width: "100px", height: "auto", margin: "10px auto" }}
            />
          </div>
          {/* <div style={{ marginTop: "2rem" }}>
            <img
              src={LiznrFav}
              alt="logo"
              style={{ width: "100px", height: "auto", margin: "20px auto" }}
            />
          </div> */}

          <div
            className="text-center py-2"
            style={{ marginTop: "5rem", marginBottom: "2rem" }}
          >
            <Typography
              variant="string"
              className="text-black fw-medium"
              sx={{
                fontFamily: "Verdana, sans-serif",
                fontSize: "20px",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              Make meetings Effortless with Liznr
            </Typography>
            <br />
            <Typography
              variant="caption"
              className="text-black text-md fw-medium"
              sx={{ fontSize: "14px" }}
            >
              Your Smart{" "}
              <span className="register-link" style={{ color: "#DE00FF" }}>
                AI
              </span>{" "}
              Assistant
            </Typography>
          </div>

          {/* <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          > */}
          {/* <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                sx={{ ariaLabel: "email" }}
              />
            </FormControl>
            <FormControl> */}
          {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: "baseline" }}
                >
                  Forgot your password?
                </Link>
              </Box> */}
          {/* <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? "error" : "primary"}
              />
            </FormControl> */}
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ForgotPassword open={open} handleClose={handleClose} /> */}
          {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
            >
              Sign in
            </Button> */}
          {/* <Typography sx={{ textAlign: "center" }}>
              Don&apos;t have an account?{" "}
              <span>
                <Link
                  href="/register"
                  variant="body2"
                  sx={{ alignSelf: "center" }}
                >
                  Sign up
                </Link>
              </span>
            </Typography> */}
          {/* </Box>
          <Divider>or</Divider>  */}
          {/* Changes made */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                // fullWidth
                variant="standard"
                sx={{
                  fontFamily: `"Google Sans", "arial","sans-serif"`,
                  border: "1px solid #dadce0",
                  width: "fit-content",
                  alignSelf: "center",
                  // borderRadius: "2.5px",
                  fontWeight: "500px",
                  fontSize: "14px",
                  height: "40px",
                  letterSpacing: "0.25px",
                  borderRadius: "4px",

                  // position: "relative",
                  // verticalAlign: "middle",
                  // whiteSpace: "nowrap",
                  backgroundColor: "#ffffff", // White background
                  color: "#000000", // Black text
                  "&:hover": {
                    backgroundColor: "#f2f2f2", // Slightly lighter background on hover
                  },
                }}
                onClick={handleGoogleLogin}
                startIcon={<i className="ci ci-google ci-1x"></i>}
              >
                {/* <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    const profileObj = jwtDecode(credentialResponse.credential); // Decoding JWT if needed

                    handleSuccess(profileObj); // Handle user profile
                  }}
                  scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events" // Add required scopes
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  // shape='circle'
                /> */}
                Sign in with Google
              </Button>

              <Button
                type="submit"
                // fullWidth
                variant="standard"
                onClick={handleLogin}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f2f2f2")
                } // Light gray on hover
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#ffffff")
                } // Reset to white
                startIcon={<i className="ci ci-microsoft ci-1x"></i>}
                size="small"
                sx={{
                  fontFamily: `"Google Sans", "arial","sans-serif"`,
                  border: "1px solid #dadce0",
                  width: "fit-content",
                  alignSelf: "center",
                  // borderRadius: "2.5px",
                  fontWeight: "500px",
                  fontSize: "14px",
                  height: "40px",
                  letterSpacing: "0.25px",
                  borderRadius: "4px",

                  // position: "relative",
                  // verticalAlign: "middle",
                  // whiteSpace: "nowrap",
                  backgroundColor: "#ffffff", // White background
                  color: "#000000", // Black text
                  "&:hover": {
                    backgroundColor: "#f2f2f2", // Slightly lighter background on hover
                  },
                }}
              >
                Sign in with Microsoft
              </Button>
              
          
            </Box>
          </Box>

          <div className="t&c" style={{ marginTop: "4.6rem" }}>
            <p className="text-sm fw-small" style={{ color: "grey" }}>
              By using Liznr you agree to the
              <br />
              <Route
                to={terms_and_conditions}
                className="register-link"
                target="_blank"
                style={{ fontSize: "12px", color: "#DE00FF" }}
              >
                {" "}
                Terms of Service
              </Route>{" "}
              and{" "}
              <Route
                to={privacy_policy}
                className="register-link"
                target="_blank"
                style={{ fontSize: "12px", color: "#DE00FF" }}
              >
                {" "}
                Privacy Policy
              </Route>
            </p>
          </div>
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}
