import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Paper,
  TablePagination,
  LinearProgress,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ReplyIcon from "@mui/icons-material/Reply";

import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";

import { authenticateWithGoogle } from "../AxiosInterceptors/api";
import { useNavigate } from "react-router-dom";
import SignIn from "../sign-in/SignIn";
import ShareIcon from "@mui/icons-material/Share";
import redirectIcon from "../../Assets/redirect.png";
import "./Calendar.css";

function Calendar() {
  const [tabValue, setTabValue] = useState(0);
  const [filter, setFilter] = useState("Has video conferencing");
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const googleClient = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const googleCalendar = process.env.REACT_APP_GOOGLE_CALENDAR_API;
  const auth_host = process.env.REACT_APP_AUTH_HOST

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [hasEvents, setHasEvents] = useState(false);

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    try{

      const initializeGoogleClient = () => {
        const client = window.google.accounts.oauth2.initTokenClient({
          client_id: googleClient, // Replace with your client ID
          scope: "https://www.googleapis.com/auth/calendar",
          access_type:"offline",
          callback: handleAuthResponse,
        });
        setTokenClient(client);
      };
  
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.oauth2
      ) {
        initializeGoogleClient();
      }
    }catch(error){
      console.log(error);
      
    }
  }, []);

  // Step 2: Initialize the Google API client library for Calendar API
  useEffect(() => {
    try{

      const loadGapiClient = () => {
        window.gapi.load("client", async () => {
          try{

            await window.gapi.client.init({
              apiKey: googleCalendar, // Replace with your API key
              discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
              ],
            });
            console.log("Google API client initialized");
            setHasEvents(true);
            calendarCallback()
          }catch(error){
            console.log(error);
            
          }
        });
      };
  
      if (window.gapi) {
        loadGapiClient();
      }
    }catch(error){
      console.log(error);
      
    }
  }, []);

  // Step 3: Handle the authentication response
  const handleAuthResponse = async (response) => {
    if (response.error) {
      console.error("Authentication failed:", response.error);
      return;
    }
    if (response.access_token) {
      setIsSignedIn(true);
      console.log("Entering");
      
      
    }

    // Fetch user profile information
  };

  const handleLogin = () => {
    if (tokenClient) {
      tokenClient.requestAccessToken();
      console.log("Token ", tokenClient);
      // setIsSignedIn(true);
    } else {
      console.error("Token client not initialized");
    }
  };

  const getCalendarEvents = async () => {
    if (!hasEvents) {
      console.error("User is not signed in");
      // handleLogin()
      return;
    }
    setLoading(true);
    try {
      console.log("Google calendar");

      const response = await window.gapi.client.calendar.events.list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      });
      if (response.status === 200) {
        setIsSignedIn(true);
        loadEvents();
      }
      setLoading(false);
      // console.log('Events:', response.result.items);
      setEvents(response.result.items);
    } catch (error) {
      console.error("Error fetching calendar events:", error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isSignedIn || hasEvents) {
  //     console.log("Signed in");

  //     getCalendarEvents();
  //   }
  // }, [hasEvents, isSignedIn]);

  useEffect(() => {
    filterEvents();
  }, [events, tabValue]);

  const filterEvents = () => {
    
    
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of today
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const filtered = events.filter((event) => {
      const eventStart = new Date(event.start.dateTime || event.start.date);

      if (tabValue === 0) {
        // "Upcoming" tab: show events only for today
        return eventStart >= today && eventStart < tomorrow;
      } else {
        // "Scheduling" tab: show events from tomorrow onward
        return eventStart >= tomorrow;
      }
    });

    setFilteredEvents(filtered);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const paginatedEvents = filteredEvents.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleGoogleAuth = async () => {
    const response = await authenticateWithGoogle();
    // const response = await initiateCalendarOAuthPopup();
    const { auth_url } = response.data;
    console.log("The data", response.data);
    if (response.status === 200) {
      window.location.href = auth_url;
      console.log("success");
    }
    // console.log(response);
  };

  const saveTokens = (accessToken, refreshToken) => {
    localStorage.setItem("calendar_access_token", accessToken);
    localStorage.setItem("calendar_refresh_token", refreshToken);
  };

  // Assume these are imported or defined somewhere
  const API_URL = `${dashboard_backend}/api/auth/`;
  // const TOKEN_EXPIRY_BUFFER = 300; // 5-minute buffer before token expiry

  // const refreshAccessToken = async () => {
  //   try {
  //     const refreshToken = getRefreshToken();
  //     if (!refreshToken) throw new Error("No refresh token available");

  //     const response = await axios.post(`${API_URL}google/token/`, { refresh_token: refreshToken });
  //     const { access_token, refresh_token } = response.data;
  //     saveTokens(access_token, refresh_token);
  //     return access_token;
  //   } catch (error) {
  //     console.error("Error refreshing access token:", error);
  //     throw error;
  //   }
  // };

  // For fetching the calendar data
  const getAccessToken = () => localStorage.getItem("calendar_access_token");
  const getRefreshToken = () => localStorage.getItem("calendar_refresh_token");
  const fetchCalendarEvents = async () => {
    let accessToken = getAccessToken();

    // Check if access token is expired
    // if (accessToken) {
    //   const tokenPayload = jwtDecode(accessToken);
    //   const isTokenExpired = tokenPayload.exp < Math.floor(Date.now() / 1000) + TOKEN_EXPIRY_BUFFER;

    //   if (isTokenExpired) {
    //     console.log("Access token expired, refreshing...");
    //     accessToken = await refreshAccessToken(); // Refresh token if expired
    //   }
    // } else {
    //   accessToken = await refreshAccessToken(); // Get a new token if none exists
    // }

    // Fetch calendar events with a valid access token
    try {
      const eventsResponse = await axios.get(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            timeMin: new Date().toISOString(), // Get events from the current time
            singleEvents: true, // Expand recurring events
            orderBy: "startTime", // Sort by start time
          },
        }
      );

      setEvents(eventsResponse.data.items); // Update events state with response data
      console.log("Fetched calendar events:", eventsResponse.data);
    } catch (error) {
      console.error("Failed to fetch calendar events:", error);
    }
  };

  // Handle the redirect back in your main application
  const calendarCallback = async ()=> {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const access = localStorage.getItem('access_token')

    if (code) {
      // console.log("Received authorization code:", code);
      try {
        // Exchange the code for tokens
        const tokenResponse =  await fetch(`${auth_host}/calendar-callback/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${access}`
          },
          body: JSON.stringify({ code: code }),
      });

      if(tokenResponse.status==200){
        await fetchEvents()
        navigate("/calendar");
      }
      } catch (error) {
        console.error("Error exchanging code for tokens:", error);
      }
    }
  };

  const loadEvents = async () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      return;
    }
    const response = await fetchEvents();
    // const response = await fetchCalendarEvents();
    console.log(response);

    if (response) {
      setEvents(response.data);
    }
  };

  useEffect(() => {
    // This is to be commented when we use the main google calendar functionality
    // loadEvents();
  }, []);


  // Updated After verification
  const fetchEvents = async()=>{
    try{
      setLoading(true)
      const response = await axios.get(`${auth_host}/fetch-meetings/`,{
        headers:{
          'Authorization':`Bearer ${localStorage.getItem('access_token')}`
        }
      })
      console.log(response.data.meetings);
      if(response.status === 200){

        setEvents(response.data.meetings.items)
        setIsSignedIn(true)
        loadEvents()
        setLoading(false)
      }
    }catch(error){
      console.log(error);
      setLoading(false)
      
    }
  }

  useEffect(()=>{
    // Uncomment this when we are verifed that the google calendar is working
   fetchEvents()
  },[])

  return (
    <Box sx={{ padding: 2, width: "100%" }}>
      {/* Tabs for "Upcoming" and "Scheduling" */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="calendar-tabs"
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? "auto" : false}
      >
        <Tab label="Today" />
        <Tab label="Upcoming" />
      </Tabs>

      {/* Filter and Action Buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mt: isMobile ? 4 : 2,
          mb: isMobile ? 2 : 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row", // Ensure the items are always in a row
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: 2,
            gap: 1, // Add spacing between items if needed
          }}
        >
          <Button
            variant="outlined"
            sx={{
              height: 40,
              borderRadius: 1,
              padding: "0 16px",
              fontSize: "14px",
              fontWeight: 400,
              borderColor: "#0f203e",
              color: "#0f203e",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
            startIcon={<FilterAltOutlinedIcon />}
          >
            Filter
          </Button>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            displayEmpty
            sx={{
              height: 40,
              borderRadius: 1,
              padding: "0 16px",
              fontSize: "14px",
              fontWeight: 400,
              border: "1px solid #0f203e",
              color: "#0f203e",
              backgroundColor: "#fff",
              "& .MuiSelect-outlined": {
                padding: 0, // Aligns the text inside Select with Button
              },
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <MenuItem value="Has video conferencing">
              Has video conferencing
            </MenuItem>
            <MenuItem value="No video conferencing">
              No video conferencing
            </MenuItem>
          </Select>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 1,
            mt: isMobile ? 2 : 0,
            mb: isMobile ? 3 : 0,
          }}
        >
          {isSignedIn ? (
            <Button
              variant="outlined"
              startIcon={<EventAvailableOutlinedIcon />}
            />
          ) : (
            <Button
              variant="outlined"
              onClick={handleLogin}
              sx={{ mb: isMobile ? 3 : 0 }}
            >
              Add Calendar
            </Button>
          )} */}
        {/* <Button variant="outlined" startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleGoogleAuth}>Add Read to a live meeting</Button> */}
        {/* <Button variant="contained">Scheduling Link</Button>
        </Box> */}
      </Box>

      {/* Meeting Table */}
      <Paper sx={{ mt: 2, p: isMobile ? 1 : 2 }}>
        <Box>
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
        {isSignedIn && !loading && (
          <Table size={isMobile ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell>Meeting</TableCell>
                <TableCell>Date & Time</TableCell>
                <TableCell>Flexible?</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedEvents.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <EventAvailableOutlinedIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Typography variant="h6">No meetings for today</Typography>
                    <Typography variant="body2">
                      There are no meetings For today Check Upcoming Meetings.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedEvents.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>{event.summary}</TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {new Date(event.start.dateTime).toLocaleString(
                          "en-US",
                          {
                            weekday: "short", // e.g., "Mon"
                            month: "short", // e.g., "Oct"
                            day: "numeric", // e.g., "30"
                          }
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {new Date(event.start.dateTime).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}{" "}
                        -{" "}
                        {new Date(event.end.dateTime).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell>{event.flexible ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <a
                        href={event.hangoutLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={redirectIcon}
                          alt="redirectIcon"
                          className="redirectIcon"
                        />
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
        {!isSignedIn && !loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<EventAvailableOutlinedIcon />}
              onClick={handleGoogleAuth}
            >
              Add Calendar
            </Button>
          </Box>
        )}
      </Paper>
      <TablePagination
        component="div"
        count={filteredEvents.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[3, 5, 10]}
      />
    </Box>
  );
}

export default Calendar;
